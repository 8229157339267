import React from 'react';
import PropTypes from 'prop-types';
import * as classes from './ExpertiseFilter.module.css';

const ProjectFilter = ({categories, selectedCategory, onCategoryChange}) => (
  <div className={classes.filterContainer}>
    {categories.map(category => (
      <button
        key={category}
        onClick={() => onCategoryChange(category)}
        className={
          selectedCategory === category ? `${classes.button} ${classes.active}` : classes.button
        }
      >
        {category}
      </button>
    ))}
  </div>
);

ProjectFilter.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  onCategoryChange: PropTypes.func.isRequired,
};

export default ProjectFilter;
